import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { device } from '../../device'
import {
  palette,
  maxContentWidth,
  spacers,
  titleStyles,
  descriptionStyles,
} from '../../styles/theme'
import customTracksSmall from './images/custom@600.jpg'
import customTracksIntermediate from './images/custom@1200.jpg'
import customTracksLarge from './images/custom@1600.jpg'

const CustomTracksContainer = styled.section.attrs({
  id: 'custom-tracks',
})`
  margin: 0;
  margin-bottom: ${spacers.spacer5};
  padding: 0;
  width: 100%;
  max-width: ${maxContentWidth}px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.intermediate} {
    margin-bottom: ${spacers.spacer6};
  }
  @media ${device.web} {
    margin-bottom: ${spacers.spacer7};
  }
`

const Title = styled.h2`
  ${titleStyles}
  margin-top: 0;
  margin-left: ${spacers.spacer2};
  margin-bottom: ${spacers.spacer1};
  margin-right: ${spacers.spacer2};
  padding: 0;
  color: ${palette.color6};
  @media ${device.intermediate} {
    margin-bottom: ${spacers.spacer3};
  }
  @media ${device.web} {
    margin-bottom: ${spacers.spacer6};
  }
`

const Description = styled.p`
  ${descriptionStyles}
  margin-top: 0;
  margin-left: ${spacers.spacer2};
  margin-bottom: ${spacers.spacer5};
  margin-right: ${spacers.spacer2};
  padding: 0;
  text-align: center;
  color: ${palette.color6};
  @media ${device.intermediate} {
    margin-bottom: ${spacers.spacer3};
    line-height: 1.5;
  }
  @media ${device.web} {
    display: none;
  }
`

const ImageWrapper = styled.div`
  margin: 0;
  margin-bottom: ${spacers.spacer5};
  padding: 0;
  width: 100%;
  height: 216px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: url(${customTracksSmall});
  background-size: cover;
  @media ${device.intermediate} {
    margin-bottom: ${spacers.spacer3};
    height: 296px;
    background: url(${customTracksIntermediate});
  }
  @media ${device.web} {
    margin-bottom: ${spacers.spacer6};
    position: relative;
    background: url(${customTracksLarge}) no-repeat center center fixed;
  }
`

const DescriptionImage = styled.span`
  margin: 0;
  margin-bottom: ${spacers.spacer6};
  padding: 0;
  display: none;
  width: 700px;
  height: ${spacers.spacer7};
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
  color: ${palette.color1};
  @media ${device.web} {
    display: inline-block;
  }
`

const DescriptionBottom = styled.p`
  ${descriptionStyles}
  margin: 0 ${spacers.spacer2};
  line-height: 1;
  padding: 0;
  text-align: center;
  color: ${palette.color6};
  @media ${device.intermediate} {
    line-height: 1.5;
  }
`

const ContactLink = styled.a`
  ${descriptionStyles}
  color: ${palette.color6};
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  @media ${device.intermediate} {
    line-height: 1.5;
  }
`

export const CustomTracks = ({ openContact }) => (
  <CustomTracksContainer>
    <Title>Custom Tracks</Title>
    <Description>
      Need a light-hearted ukelele track for a diapers ad? A metal version of a
      Christmas classic? We got you covered.
    </Description>
    <ImageWrapper>
      <DescriptionImage>
        Need a light-hearted ukelele track for a diapers ad? A metal version of
        a Christmas classic?
      </DescriptionImage>
    </ImageWrapper>
    <DescriptionBottom>
      <ContactLink onClick={openContact}>Drop us a line</ContactLink> and
      we&apos;ll get you hooked up.
    </DescriptionBottom>
  </CustomTracksContainer>
)

CustomTracks.propTypes = {
  openContact: PropTypes.func.isRequired,
}
