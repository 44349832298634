import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { device } from '../../device'
import { HoverPlayIcon } from '../../components/icons/hover-play'
import { HoverPauseIcon } from '../../components/icons/hover-pause'
import {
  palette,
  maxContentWidth,
  spacers,
  titleStyles,
  descriptionStyles,
} from '../../styles/theme'
import tracks from './tracks.json'
import { Player } from '../player'

const paddingWrapper = 22
const albumCoverWidth = 187
const demoCardWrapperWidth = paddingWrapper * 2 + albumCoverWidth
const albumDescriptionWidth = 187

const DemoCardWrapper = styled.div`
  padding: ${paddingWrapper}px;
  margin-top: 0;
  margin-right: ${props => (props.isLast ? '0' : spacers.spacer2)};
  margin-bottom: 0;
  margin-left: 0;
  border-radius: 5px;
  width: ${demoCardWrapperWidth}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  background-color: ${palette.color2};
  cursor: pointer;
  @media ${device.intermediate} {
    margin-right: ${props => (props.isLast ? '0' : spacers.spacer3)};
  }
`

const AlbumCoverContainer = styled.div`
  margin: 0;
  margin-bottom: ${spacers.spacer2};
  width: ${albumCoverWidth}px;
  height: ${albumCoverWidth}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-image: url("${props => props.src}");
  background-size: cover;
`

const PlayPauseButton = styled.div`
  width: 35%;
  height: 35%;
  display: none;
  ${DemoCardWrapper}:hover & {
    display: block;
  }
`

const AlbumCover = ({ demoId, currentDemo, playing, src }) => (
  <AlbumCoverContainer src={src}>
    <PlayPauseButton>
      {demoId === currentDemo && playing ? (
        <HoverPauseIcon />
      ) : (
        <HoverPlayIcon />
      )}
    </PlayPauseButton>
  </AlbumCoverContainer>
)

AlbumCover.propTypes = {
  src: PropTypes.string.isRequired,
  demoId: PropTypes.string.isRequired,
  currentDemo: PropTypes.string,
  playing: PropTypes.bool.isRequired,
}

const AlbumDescription = styled.div`
  margin: 0;
  padding: 0;
  width: ${albumDescriptionWidth}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`

const SongTitle = styled.span`
  ${descriptionStyles}
  line-height: 1.2;
  margin: 0;
  padding: 0;
  display: inline-block;
  color: ${palette.color6};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

const Genre = styled.span`
  ${descriptionStyles}
  line-height: 1.2;
  margin: 0;
  margin-bottom: ${spacers.spacer4};
  padding: 0;
  display: inline-block;
  color: ${palette.color4};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

const AlbumTitle = styled.span`
  ${descriptionStyles}
  line-height: 1;
  margin: 0;
  padding: 0;
  display: inline-block;
  color: ${palette.color6};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

const DemoCard = ({
  albumTitle,
  genre,
  songTitle,
  currentDemo,
  id,
  src,
  playing,
  start,
  play,
  pause,
  albumCover,
  isLast,
}) => {
  const onClick = () => {
    if (playing && currentDemo === id) {
      pause(id)
    } else if (!playing && currentDemo === id) {
      play(id)
    } else {
      start({ src, demoId: id, songTitle, albumTitle, albumCover })
    }
  }
  return (
    <DemoCardWrapper onClick={onClick} isLast={isLast}>
      <AlbumCover
        src={albumCover}
        demoId={id}
        currentDemo={currentDemo}
        playing={playing}
      />
      <AlbumDescription>
        <SongTitle>{songTitle}</SongTitle>
        <Genre>{genre}</Genre>
        <AlbumTitle>{albumTitle}</AlbumTitle>
      </AlbumDescription>
    </DemoCardWrapper>
  )
}

DemoCard.propTypes = {
  albumTitle: PropTypes.string.isRequired,
  songTitle: PropTypes.string.isRequired,
  genre: PropTypes.string.isRequired,
  start: PropTypes.func.isRequired,
  play: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  currentDemo: PropTypes.string,
  playing: PropTypes.bool.isRequired,
  albumCover: PropTypes.string.isRequired,
  isLast: PropTypes.bool,
}

const TracksContainer = styled.section.attrs({
  id: 'tracks',
})`
  margin: 0;
  margin-bottom: ${spacers.spacer5};
  padding: 0;
  width: 100%;
  max-width: ${maxContentWidth}px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.intermediate} {
    margin-bottom: ${spacers.spacer6};
  }
  @media ${device.web} {
    margin-bottom: ${spacers.spacer7};
  }
`

const Title = styled.h2`
  ${titleStyles}
  margin-top: 0;
  margin-left: ${spacers.spacer2};
  margin-bottom: ${spacers.spacer1};
  margin-right: ${spacers.spacer2};
  padding: 0;
  color: ${palette.color6};
  @media ${device.intermediate} {
    margin-bottom: ${spacers.spacer3};
  }
`

const Description = styled.p`
  ${descriptionStyles}
  margin-top: 0;
  margin-left: ${spacers.spacer2};
  margin-bottom: ${spacers.spacer5};
  margin-right: ${spacers.spacer2};
  padding: 0;
  text-align: center;
  color: ${palette.color6};
  @media ${device.intermediate} {
    margin-bottom: ${spacers.spacer3};
  }
  @media ${device.web} {
    margin-bottom: ${spacers.spacer5};
  }
`

const ContactWrapper = styled.div`
  margin: 0;
  padding-top: ${spacers.spacer1};
  padding-left: ${spacers.spacer2};
  padding-bottom: ${spacers.spacer2};
  padding-right: ${spacers.spacer2};
  width: calc(100% - 44px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 5px;
  background: ${palette.color2};
  @media ${device.intermediate} {
    padding: ${spacers.spacer3};
    width: calc(100% - 96px);
  }
  @media ${device.web} {
    width: 700px;
  }
`

const DescriptionContact = styled.span`
  ${descriptionStyles}
  margin: 0;
  margin-bottom: ${spacers.spacer1};
  padding: 0;
  display: inline-block;
  text-align: center;
  color: ${palette.color6};
  @media ${device.intermediate} {
    margin-bottom: ${spacers.spacer3};
  }
`

const ContactLink = styled.a`
  margin: 0;
  width: 100%;
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: 1.5rem;
  line-height: 2;
  text-align: center;
  text-decoration: none;
  box-shadow: none;
  border: none;
  border-radius: 25px;
  background: ${palette.color5};
  color: ${palette.color1};
  cursor: pointer;
  @media ${device.intermediate} {
    width: 286px;
    height: ${spacers.spacer6};
  }
`

const CardsCarousel = styled.div`
  margin: 0;
  margin-bottom: ${spacers.spacer5};
  padding: 0;
  max-width: calc(100% - 44px);
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  @media ${device.intermediate} {
    margin-bottom: ${spacers.spacer3};
    width: calc(100% - 96px);
  }
  @media ${device.web} {
    margin-bottom: ${spacers.spacer6};
  }
`

const Modal = ({ children }) => {
  const portalDiv =
    typeof document !== 'undefined' && document.getElementById('portal')
  return portalDiv ? ReactDOM.createPortal(children, portalDiv) : null
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export const Demos = ({
  currentDemo,
  start,
  play,
  pause,
  playing,
  openContact,
  notifyPlayerClosed,
  notifyPlaying,
}) => (
  <TracksContainer>
    <Title>Tracks</Title>
    <Description>Tracks available for sync licensing.</Description>
    <CardsCarousel>
      {tracks.map(
        ({ albumTitle, genre, songTitle, id, src, albumCover }, index) => (
          <React.Fragment key={id}>
            <DemoCard
              albumTitle={albumTitle}
              genre={genre}
              songTitle={songTitle}
              id={id}
              src={src}
              currentDemo={currentDemo}
              start={start}
              play={play}
              pause={pause}
              playing={playing}
              albumCover={albumCover}
              isLast={index === tracks.length - 1}
            />
            {typeof document !== 'undefined' ? (
              <Modal>
                <Player
                  id={id}
                  active={currentDemo === id}
                  albumTitle={albumTitle}
                  albumCover={albumCover}
                  notifyClosed={notifyPlayerClosed}
                  notifyPlaying={notifyPlaying}
                  playing={playing}
                  songTitle={songTitle}
                  src={src}
                />
              </Modal>
            ) : null}
          </React.Fragment>
        ),
      )}
    </CardsCarousel>
    <ContactWrapper>
      <DescriptionContact>Interested in what you hear?</DescriptionContact>
      <ContactLink onClick={openContact}>Get a quote</ContactLink>
    </ContactWrapper>
  </TracksContainer>
)

Demos.propTypes = {
  currentDemo: PropTypes.string,
  start: PropTypes.func.isRequired,
  play: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
  playing: PropTypes.bool.isRequired,
  openContact: PropTypes.func.isRequired,
  notifyPlayerClosed: PropTypes.func.isRequired,
  notifyPlaying: PropTypes.func.isRequired,
}
