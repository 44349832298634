import React from 'react'
import { Svg } from './svg'
import { palette } from '../../styles/theme'

export const ClosePlayerIcon = () => (
  <Svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fill={palette.color1}
        d="M17.61,0.29L0.29,17.61c-0.25,0.25-0.36,0.53-0.25,0.64l1.71,1.71c0.11,0.11,0.39-0.01,0.64-0.25L19.71,2.39 c0.25-0.25,0.36-0.53,0.25-0.64l-1.71-1.71C18.14-0.07,17.85,0.05,17.61,0.29z"
      />
      <path
        fill={palette.color1}
        d="M0.29,2.39l17.31,17.31c0.25,0.25,0.53,0.36,0.64,0.25l1.71-1.71c0.11-0.11-0.01-0.39-0.25-0.64L2.39,0.29 C2.15,0.05,1.86-0.07,1.75,0.04L0.04,1.75C-0.07,1.86,0.05,2.15,0.29,2.39z"
      />
    </g>
  </Svg>
)
