import React from 'react'
import { Svg } from './svg'
import { palette } from '../../styles/theme'

export const PausePlayerIcon = () => (
  <Svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fill={palette.color1}
        d="M0.04,0.49l0,19.02c0,0.27,0.19,0.49,0.42,0.49h3.77c0.23,0,0.42-0.22,0.42-0.49V0.49C4.65,0.22,4.46,0,4.23,0 H0.46C0.23,0,0.04,0.22,0.04,0.49z"
      />
      <path
        fill={palette.color1}
        d="M12.35,0.49l0,19.02c0,0.27,0.19,0.49,0.42,0.49h3.77c0.23,0,0.42-0.22,0.42-0.49V0.49 c0-0.27-0.19-0.49-0.42-0.49h-3.77C12.54,0,12.35,0.22,12.35,0.49z"
      />
    </g>
  </Svg>
)
