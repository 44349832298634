import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { device } from '../../device'
import { HoverPlayIcon } from '../../components/icons/hover-play'
import { HoverPauseIcon } from '../../components/icons/hover-pause'
import {
  palette,
  maxContentWidth,
  spacers,
  titleStyles,
  descriptionStyles,
} from '../../styles/theme'
import { Player } from '../player'
import { layers } from '../../styles/layers'

import mixingSmall from './images/mixing@600.jpg'
import mixingLilSquare from './images/mixing@331.jpg'
import mixingLarge from './images/mixing@1600.jpg'

const DemoCardWrapper = styled.div`
  padding: 0;
  margin: 0;
  flex: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
`

const Spacer = styled.div`
  padding: 0;
  margin: 0;
  width: ${spacers.spacer2};
`

const AlbumCoverContainer = styled.div`
  margin: 0;
  margin-bottom: ${spacers.spacer1};
  width: 100%;
  padding-bottom: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-image: url("${props => props.albumCover}");
  background-size: cover;
  position: relative;
`

const PlayPauseButtonOuter = styled.div`
  position: absolute;
  z-index: ${layers.background};
  height: 100%;
  width: 100%;
  top: 0;
  cursor: pointer;
  display: none;
  ${DemoCardWrapper}:hover & {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const PlayPauseButtonInner = styled.div`
  width: 35%;
  height: 35%;
`

const PlayPauseButton = ({ children }) => (
  <PlayPauseButtonOuter>
    <PlayPauseButtonInner>{children}</PlayPauseButtonInner>
  </PlayPauseButtonOuter>
)

PlayPauseButton.propTypes = {
  children: PropTypes.node.isRequired,
}

const AlbumCover = ({ demoId, currentDemo, playing, albumCover }) => (
  <AlbumCoverContainer albumCover={albumCover}>
    <PlayPauseButton>
      {demoId === currentDemo && playing ? (
        <HoverPauseIcon />
      ) : (
        <HoverPlayIcon />
      )}
    </PlayPauseButton>
  </AlbumCoverContainer>
)

AlbumCover.propTypes = {
  demoId: PropTypes.string.isRequired,
  albumCover: PropTypes.string.isRequired,
  currentDemo: PropTypes.string,
  playing: PropTypes.bool.isRequired,
}

const DescriptionCard = styled.span`
  margin: 0;
  margin-bottom: ${spacers.spacer1};
  padding: 0;
  display: inline-block;
  color: ${palette.color6};
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
`

const Modal = ({ children }) => {
  const portalDiv =
    typeof document !== 'undefined' && document.getElementById('portal')
  return portalDiv ? ReactDOM.createPortal(children, portalDiv) : null
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

const DemoCard = ({
  time,
  albumTitle,
  albumCover,
  genre,
  songTitle,
  currentDemo,
  id,
  src,
  playing,
  start,
  play,
  pause,
  notifyPlayerClosed,
  notifyPlaying,
}) => {
  // TODO: Optimize this shit with useCallback or smth
  const onClick = () => {
    if (playing && currentDemo === id) {
      pause(id)
    } else if (!playing && currentDemo === id) {
      play(id)
    } else {
      start({ src, demoId: id, songTitle, albumTitle, albumCover })
    }
  }
  return (
    <>
      <DemoCardWrapper onClick={onClick}>
        <AlbumCover
          demoId={id}
          currentDemo={currentDemo}
          playing={playing}
          albumCover={albumCover}
        />
        <DescriptionCard>{time}</DescriptionCard>
      </DemoCardWrapper>
      {typeof document !== 'undefined' ? (
        <Modal>
          <Player
            id={id}
            active={currentDemo === id}
            albumTitle={albumTitle}
            albumCover={albumCover}
            notifyClosed={notifyPlayerClosed}
            notifyPlaying={notifyPlaying}
            playing={playing}
            songTitle={songTitle}
            src={src}
          />
        </Modal>
      ) : null}
    </>
  )
}

DemoCard.propTypes = {
  time: PropTypes.string.isRequired,
  albumTitle: PropTypes.string.isRequired,
  albumCover: PropTypes.string.isRequired,
  songTitle: PropTypes.string.isRequired,
  genre: PropTypes.string.isRequired,
  start: PropTypes.func.isRequired,
  play: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  currentDemo: PropTypes.string,
  playing: PropTypes.bool.isRequired,
  notifyPlayerClosed: PropTypes.func.isRequired,
  notifyPlaying: PropTypes.func.isRequired,
}

const CardsCarousel = styled.div`
  margin: 0;
  padding-top: ${spacers.spacer2};
  padding-left: ${spacers.spacer2};
  padding-bottom: 0;
  padding-right: ${spacers.spacer2};
  max-width: 331px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${palette.color2};
  border-radius: 5px;
  @media ${device.intermediate} {
    margin-right: 36px;
    width: 331px;
    height: 200px;
    order: 3;
  }
  @media ${device.web} {
    margin: 0;
    order: 4;
  }
`

const MixingContainer = styled.section.attrs({
  id: 'mixing',
})`
  margin: 0 0 36px 0;
  width: 100%;
  max-width: ${maxContentWidth}px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.intermediate} {
    margin-bottom: 48px;
  }
  @media ${device.web} {
    margin-bottom: 72px;
  }
`

const Title = styled.h2`
  ${titleStyles}
  margin: 0 0 11px 0;
  padding: 0;
  text-align: center;
  color: ${palette.color6};
  @media ${device.intermediate} {
    margin-bottom: 24px;
  }
  @media ${device.web} {
    margin-bottom: 48px;
  }
`

const MixingWrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.intermediate} {
    width: 698px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media ${device.web} {
    width: 100%;
  }
`

const BaseDescription = styled.span`
  ${descriptionStyles}
  margin: 0;
  padding: 0 22px;
  text-align: center;
  color: ${palette.color6};
  > b {
    font-family: 'Jorge-Luis-Cafe-Cafe';
  }
  @media ${device.intermediate} {
    margin: 0;
    margin-right: 36px;
    margin-bottom: 36px;
    width: 331px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
`

const Description = styled(BaseDescription)`
  margin: 0 0 36px 0;
  @media ${device.intermediate} {
    order: 1;
    line-height: 1.5;
  }
  @media ${device.web} {
    margin: 0;
    margin-bottom: 48px;
    padding: 0;
    width: 700px;
    height: 72px;
  }
`

const DescriptionCenterWrapper = styled.div`
  margin: 0 0 36px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  @media ${device.intermediate} {
    margin: 0;
    padding: 0;
    width: 331px;
    height: 200px;
    border-radius: 5px;
    order: 4;
  }
  @media ${device.web} {
    margin: 0;
    order: 3;
  }
`

const DescriptionCenter = styled(BaseDescription)`
  margin: 0;
  margin-bottom: ${props => (props.last ? 0 : '11px')};
  @media ${device.intermediate} {
    margin: 0;
    padding: 0;
    display: inline-block;
    line-height: 1.2;
    text-align: center;
  }
  @media ${device.web} {
    text-align: left;
    color: ${palette.color1};
  }
`

const MixingSubWrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.intermediate} {
    flex-direction: row;
    order: 3;
  }
  @media ${device.web} {
    padding: 48px 72px;
    border-radius: 5px;
    justify-content: space-between;
    background: url(${mixingLarge}) no-repeat center center;
    background-size: cover;
  }
`

const ImageWrapper = styled.div`
  margin: 0 0 36px 0;
  padding: 0;
  width: 100%;
  height: 216px;
  background: url(${mixingSmall});
  background-size: cover;
  @media ${device.intermediate} {
    background: url(${mixingLilSquare});
    margin: 0;
    margin-bottom: 36px;
    width: 331px;
    height: 200px;
    border-radius: 5px;
    order: 2;
  }
  @media ${device.web} {
    display: none;
  }
`

export const MixingAndMastering = ({
  currentDemo,
  start,
  play,
  pause,
  playing,
  notifyPlayerClosed,
  notifyPlaying,
}) => (
  <MixingContainer>
    <Title>Mixing &amp; Mastering</Title>
    <MixingWrapper>
      <Description>
        So you&apos;ve recorded all the instrument tracks for your song or album
        and now need to get it in radio-ready shape.
      </Description>
      <ImageWrapper />
      <MixingSubWrapper>
        <DescriptionCenterWrapper>
          <DescriptionCenter>
            <b>Mixing</b> is where we make your artistic vision shine.
          </DescriptionCenter>
          <DescriptionCenter last={true}>
            <b>Mastering</b> is where we enable your listeners to enjoy your
            music as it was intended in a wide variety of devices.
          </DescriptionCenter>
        </DescriptionCenterWrapper>
        <CardsCarousel>
          <DemoCard
            time="Before"
            albumTitle="Before M&M"
            songTitle="Demand Answers"
            albumCover="synths-drums-and-rock-and-roll/cover.jpg"
            genre="Rock"
            currentDemo={currentDemo}
            id="before-mm"
            src="demand-answers-before.mp3"
            start={start}
            play={play}
            pause={pause}
            playing={playing}
            notifyPlayerClosed={notifyPlayerClosed}
            notifyPlaying={notifyPlaying}
          />
          <Spacer />
          <DemoCard
            time="After"
            albumTitle="After M&M"
            songTitle="Demand Answers"
            albumCover="synths-drums-and-rock-and-roll/cover.jpg"
            genre="Rock"
            currentDemo={currentDemo}
            id="after-mm"
            src="synths-drums-and-rock-and-roll/demand-answers.mp3"
            start={start}
            play={play}
            pause={pause}
            playing={playing}
            notifyPlayerClosed={notifyPlayerClosed}
            notifyPlaying={notifyPlaying}
          />
        </CardsCarousel>
      </MixingSubWrapper>
    </MixingWrapper>
  </MixingContainer>
)

MixingAndMastering.propTypes = {
  currentDemo: PropTypes.string,
  start: PropTypes.func.isRequired,
  play: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
  playing: PropTypes.bool.isRequired,
  notifyPlayerClosed: PropTypes.func.isRequired,
  notifyPlaying: PropTypes.func.isRequired,
}
