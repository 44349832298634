import React from 'react'
import styled from 'styled-components'

const OuterContainer = styled.div`
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const InnerContainer = styled.div`
  display: inline-block;
  position: relative;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 80%;
    height: 80%;
    margin: ${p => p.size * 0.15}px;
    border: ${p => p.size * 0.15}px solid white;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: white transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Spinner = () => (
  <OuterContainer>
    <InnerContainer size={27}>
      <div></div>
      <div></div>
      <div></div>
    </InnerContainer>
  </OuterContainer>
)
