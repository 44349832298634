import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { spacers } from '../../styles/theme'
import { PlayPlayerIcon } from '../icons/play-player'
import { PausePlayerIcon } from '../icons/pause-player'
import { Spinner } from '../icons/spinner-player'

const ButtonContainer = styled.div`
  margin: 0;
  margin-right: ${spacers.spacer1};
  padding: 0;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ButtonIconWrapper = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

export const PlayPauseButton = ({ onClick, playing, loading }) => (
  <ButtonContainer onClick={onClick}>
    {loading ? (
      <Spinner />
    ) : (
      <ButtonIconWrapper>
        {playing ? <PausePlayerIcon /> : <PlayPlayerIcon />}
      </ButtonIconWrapper>
    )}
  </ButtonContainer>
)

PlayPauseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  playing: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
}
