import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import { palette, spacers, titleStyles } from '../../styles/theme'
import { layers } from '../../styles/layers'
import { useOutsideClick } from '../../utils/useOutsideClick'
import { TwitterIcon } from '../icons/twitter'
import { FacebookIcon } from '../icons/facebook'
import { InstagramIcon } from '../icons/instagram'
import { smoothScroll } from '../../utils/smoothScroll'

const openEffect = keyframes`
  from {
    width: 0;
  }
  to {
    width: 250px;
  }
`

const socialIconDimensions = { size: 26 }

const ShadowContainer = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${layers.menuShadow};
  background: ${palette.color1};
  opacity: 0.8;
`

const MenuContainer = styled.div`
  padding-left: ${spacers.spacer2};
  padding-top: 15px;
  padding-bottom: 15px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: ${layers.menu};
  width: 250px;
  display: flex;
  overflow: scroll;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${palette.color6};
  animation-name: ${openEffect};
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
`

const MenuNavigationPrimary = styled.nav`
  width: 200px;
  display: flex;
`

const ItemsPrimary = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`

const ItemPrimary = styled.li`
  padding: 0;
  margin-bottom: 11px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const LinkPrimary = styled.a`
  ${titleStyles}
  padding: 0;
  margin: 0;
  color: ${palette.color1};
  text-decoration: none;
  box-shadow: none;
`

const MenuNavigationSecondary = styled.section`
  margin: 0;
  margin-top: ${spacers.spacer6};
  width: 200px;
  display: flex;
`

const MenuNavigationInfo = styled.section`
  margin: auto 0 0 0;
  width: 200px;
  display: flex;
  flex-direction: column;
`

const SocialIcons = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
`

const SocialIconItem = styled.li`
  margin: 0;
  padding-right: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const SocialLink = styled.a.attrs({
  target: '_blank',
})`
  width: ${socialIconDimensions.size};
  height: ${socialIconDimensions.size};
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-shadow: none;
`

const RightInfo = styled.p`
  margin: 0;
  margin-top: 26px;
  padding: 0;
  display: block;
  width: 200px;
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: 24px;
  line-height: 1;
  color: ${palette.color1};
`

export const Menu = ({ open, closeMenu }) => {
  const ref = useRef()

  const handleClick = e => {
    closeMenu()
    e.currentTarget.getAttribute('href').includes('#') && smoothScroll(e)
  }

  useOutsideClick(ref, () => closeMenu())

  return open ? (
    <React.Fragment>
      <MenuContainer ref={ref}>
        <MenuNavigationPrimary>
          <ItemsPrimary>
            <ItemPrimary>
              <LinkPrimary href={'#home'} onClick={handleClick}>
                Home
              </LinkPrimary>
            </ItemPrimary>
            <ItemPrimary>
              <LinkPrimary href={'#tracks'} onClick={handleClick}>
                Tracks
              </LinkPrimary>
            </ItemPrimary>
            <ItemPrimary>
              <LinkPrimary href={'#custom-tracks'} onClick={handleClick}>
                Custom
              </LinkPrimary>
            </ItemPrimary>
            <ItemPrimary>
              <LinkPrimary href={'#mixing'} onClick={handleClick}>
                Mixing &amp; Mastering
              </LinkPrimary>
            </ItemPrimary>
            <ItemPrimary>
              <LinkPrimary href={'#plugins'} onClick={handleClick}>
                Plugins
              </LinkPrimary>
            </ItemPrimary>
          </ItemsPrimary>
        </MenuNavigationPrimary>
        <MenuNavigationSecondary>
          <ItemsPrimary>
            <ItemPrimary>
              <LinkPrimary href="/blog" target="_blank" onClick={handleClick}>
                Blog
              </LinkPrimary>
            </ItemPrimary>
          </ItemsPrimary>
        </MenuNavigationSecondary>
        <MenuNavigationInfo>
          <SocialIcons>
            <SocialIconItem>
              <SocialLink href="https://facebook.com" onClick={handleClick}>
                <FacebookIcon size={socialIconDimensions.size} />
              </SocialLink>
            </SocialIconItem>
            <SocialIconItem>
              <SocialLink href="https://twitter.com" onClick={handleClick}>
                <TwitterIcon size={socialIconDimensions.size} />
              </SocialLink>
            </SocialIconItem>
            <SocialIconItem>
              <SocialLink href="https://instagram.com" onClick={handleClick}>
                <InstagramIcon size={socialIconDimensions.size} />
              </SocialLink>
            </SocialIconItem>
          </SocialIcons>
          <RightInfo>&copy; 2020 thm.com</RightInfo>
        </MenuNavigationInfo>
      </MenuContainer>
      <ShadowContainer />
    </React.Fragment>
  ) : null
}

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
}
