import React from 'react'
import PropTypes from 'prop-types'

import { palette } from '../../styles/theme'
import { Svg } from './svg'

export const HamburguerIcon = ({ fill = palette.color1, height, width }) => {
  const viewBox = `0 0 ${width} ${height}`
  return (
    <Svg
      viewBox={viewBox}
      id="icon-hamburguer"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill={fill}
          d="M34.14,23L0.86,23C0.38,23,0,23.13,0,23.28v2.44C0,25.87,0.38,26,0.86,26h33.29c0.47,0,0.86-0.12,0.86-0.28 v-2.44C35,23.13,34.62,23,34.14,23z"
        />
        <path
          fill={fill}
          d="M34.14,0L0.86,0C0.38,0,0,0.13,0,0.28v2.44C0,2.87,0.38,3,0.86,3h33.29C34.62,3,35,2.87,35,2.72V0.28 C35,0.13,34.62,0,34.14,0z"
        />
        <path
          fill={fill}
          d="M34.14,11.51l-33.29,0C0.38,11.51,0,11.63,0,11.78v2.44c0,0.15,0.38,0.28,0.86,0.28h33.29 c0.47,0,0.86-0.12,0.86-0.28v-2.44C35,11.63,34.62,11.51,34.14,11.51z"
        />
      </g>
    </Svg>
  )
}

HamburguerIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}
