import React from 'react'
import { Svg } from './svg'

export const AlertContactIcon = () => (
  <Svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fill="#FF0000"
        d="M11,14.8c-0.3,0-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8S10.7,17,11,17c0.3,0,0.6-0.1,0.8-0.3 s0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8S11.3,14.8,11,14.8z"
      />
      <polygon fill="#FF0000" points="11.7,4.1 10.4,4.1 10.4,12.8 11.7,12.7" />
      <path
        fill="#FF0000"
        d="M11,0C4.9,0,0,4.9,0,11c0,6.1,4.9,11,11,11s11-4.9,11-11C22,4.9,17.1,0,11,0z M11,20.4c-5.2,0-9.4-4.2-9.4-9.4 S5.8,1.6,11,1.6s9.4,4.2,9.4,9.4S16.2,20.4,11,20.4z"
      />
    </g>
  </Svg>
)
