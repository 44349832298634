import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import isEmail from 'validator/es/lib/isEmail'
import isLength from 'validator/es/lib/isLength'

import { device } from '../../device'
import { layers } from '../../styles/layers'
import {
  palette,
  spacers,
  titleStyles,
  descriptionStyles,
  absoluteTitleStyles,
} from '../../styles/theme'
import { CloseContactIcon } from '../icons/close-contact'
import { AlertContactIcon } from '../icons/alert-contact'
import { CheckContactIcon } from '../icons/check-contact'
import { FacebookIcon } from '../icons/facebook'
import { TwitterIcon } from '../icons/twitter'
import { InstagramIcon } from '../icons/instagram'

const effectLoading = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const Loading = styled.div`
  width: 28px;
  height: 28px;
  margin: auto;
  border: 3px solid ${palette.color6};
  border-radius: 50%;
  border-top: 3px solid transparent;
  animation: ${effectLoading} 0.8s linear infinite;
  @media ${device.web} {
    border: 3px solid ${palette.color1};
    border-top: 3px solid ${palette.color5};
  }
`

const effectWindow = keyframes`
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
`

const effectWindowWeb = keyframes`
  0% {
    bottom: -600px;
  }
  100% {
    bottom: 0;
  }
`

const Shadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${layers.contact};
  padding: ${spacers.spacer2};
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  @media ${device.web} {
    top: auto;
    left: auto;
    right: ${spacers.spacer7};
    padding: 0;
    width: 800px;
    height: 600px;
    max-height: calc(100vh - 92px);
    border-radius: 0;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    animation-name: ${effectWindowWeb};
    animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
  }
`

const Container = styled.div`
  position: relative;
  margin: 0;
  padding: ${spacers.spacer2};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${palette.color6};
  border-radius: 15px;
  @media ${device.web} {
    padding: 0;
    border-radius: 0;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
`

const FormContainer = styled(Container)`
  animation-name: ${effectWindow};
  animation-duration: 0.22s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  @media ${device.web} {
    background-color: ${palette.color2};
  }
`

const HeaderContact = styled.div`
  margin: 0;
  margin-bottom: ${spacers.spacer2};
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.web} {
    width: 100%;
    height: 56px;
    margin-bottom: ${spacers.spacer3};
    padding: 0 ${spacers.spacer5};
    background-color: ${palette.color6};
    border-radius: 0;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
`

const CloseWrapper = styled.div`
  position: absolute;
  top: ${spacers.spacer1};
  right: ${spacers.spacer1};
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  cursor: pointer;
  @media ${device.web} {
    top: 13px;
    right: 16px;
  }
`

const ContactTitle = styled.h2`
  ${titleStyles}
  margin: 0;
  padding: 0;
  color: ${palette.color1};
`

const Form = styled.form`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media ${device.web} {
    padding-top: 0;
    padding-left: ${spacers.spacer5};
    padding-bottom: ${spacers.spacer3};
    padding-right: ${spacers.spacer5};
  }
`

const EmailWrapper = styled.div`
  margin: 0;
  margin-bottom: ${spacers.spacer2};
  padding: 0 ${spacers.spacer1};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: ${palette.color1};
  border-radius: 5px;
`

const Email = styled.input.attrs({
  id: 'idEmail',
  type: 'email',
  name: 'email',
  autocomplete: 'off',
  required: true,
  placeholder: 'email@example.com',
})`
  ${descriptionStyles}
  margin: 1px 0;
  padding: 0;
  width: 100%;
  max-height: 100%;
  border: none;
  background: ${palette.color1};
  color: ${palette.color6};
  :focus {
    outline: none;
  }
`

const AlertWrapper = styled.div`
  margin: 0;
  margin-left: ${spacers.spacer1};
  padding: 0;
  width: 22px;
  height: 22px;
`

const AlertMessageWrapper = styled.div`
  margin-top: ${spacers.spacer1};
  margin-left: ${spacers.spacer1};
  margin-bottom: auto;
  margin-right: 0;
  padding: 0;
  width: 22px;
  height: 22px;
`

const MessageWrapper = styled.div`
  flex-grow: 1;
  margin: 0;
  margin-bottom: ${spacers.spacer2};
  padding: 0 ${spacers.spacer1};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${palette.color1};
  border-radius: 5px;
`

const Message = styled.textarea.attrs({
  name: 'message',
  placeholder: 'Say hello!',
})`
  ${descriptionStyles}
  margin: 0;
  padding: ${spacers.spacer1} 0;
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  background: ${palette.color1};
  color: ${palette.color6};
  :focus {
    outline: none;
  }
  @media ${device.web} {
    line-height: 1.5;
  }
`

const Submit = styled.button`
  ${absoluteTitleStyles}
  line-height: 1.33;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 30px;
  background: ${props => (props.disabled ? palette.color5 : palette.color1)};
  color: ${props => (props.disabled ? palette.color4 : palette.color6)};
  cursor: ${props => (props.disabled ? 'no-drop' : 'pointer')};
  outline: none;
  @media ${device.web} {
    width: 286px;
    background: ${props => (props.disabled ? palette.color1 : palette.color5)};
    color: ${props => (props.disabled ? palette.color3 : palette.color1)};
  }
`

const FeedbackTitle = styled.h2`
  ${titleStyles}
  margin: 0;
  margin-bottom: ${spacers.spacer2};
  padding: 0;
  font-family: 'Jorge-Luis-Cafe-Cafe';
  color: ${palette.color1};
`

const effectIcon = keyframes`
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`

const FeedbackIcon = styled.div`
  margin: 0;
  margin-bottom: ${spacers.spacer2};
  padding: 0;
  width: 60px;
  height: 60px;
  animation-name: ${effectIcon};
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
`

const FeedbackDescription = styled.span`
  ${descriptionStyles}
  line-height: 1;
  margin: 0;
  margin-bottom: ${spacers.spacer2};
  padding: 0;
  color: ${palette.color1};
`

const FeedbackWrapperBottom = styled.div`
  position: absolute;
  bottom: ${spacers.spacer1};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const FeedbackDescriptionBottom = styled(FeedbackDescription)`
  margin: 0;
  margin-bottom: ${spacers.spacer1};
`

const FeedbackSocial = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SocialIconWrapper = styled.a.attrs({
  target: '_blank',
})`
  display: inline-block;
  margin: 0;
  margin-right: ${props => (props.last ? 0 : spacers.spacer1)};
  padding: 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
`

export const ContactForm = ({ close }) => {
  const [status, setStatus] = useState('INITIAL')
  const [loading, setLoading] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [messageValid, setMessageValid] = useState(false)
  const [abandonedEmail, setAbandonedEmail] = useState(false)
  const [abandonedMessage, setAbandonedMessage] = useState(false)

  const emailRef = useRef(null)
  const messageRef = useRef(null)

  const validateEmail = () => {
    setEmailValid(isEmail(emailRef.current.value.trim()))
  }

  const validateMessage = () => {
    setMessageValid(
      isLength(messageRef.current.value.trim(), {
        min: 5,
        max: 10000,
      }),
    )
  }

  const submitForm = ev => {
    ev.preventDefault()
    setLoading(true)
    if (!loading) {
      if (
        process.env.NODE_ENV !== 'development' &&
        emailValid &&
        messageValid
      ) {
        const form = ev.target
        const data = new FormData(form)
        const xhr = new XMLHttpRequest()
        xhr.open(form.method, form.action)
        xhr.setRequestHeader('Accept', 'application/json')
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== XMLHttpRequest.DONE) return
          if (xhr.status === 200) {
            form.reset()
            setStatus('SUCCESS')
            setLoading(false)
          } else {
            setStatus('ERROR')
            setLoading(false)
          }
        }
        xhr.send(data)
      } else {
        setTimeout(() => {
          setStatus('ERROR')
          setLoading(false)
        }, 2000)
      }
    }
  }

  const disabled = !(emailValid && messageValid)
  let content
  if (status === 'INITIAL') {
    content = (
      <FormContainer>
        <HeaderContact>
          <ContactTitle>Contact us</ContactTitle>
          <CloseWrapper onClick={close}>
            <CloseContactIcon />
          </CloseWrapper>
        </HeaderContact>
        <Form
          onSubmit={submitForm}
          action="/api/send-email"
          method="POST"
        >
          <EmailWrapper>
            <Email
              ref={emailRef}
              onBlur={() => setAbandonedEmail(true)}
              onKeyUp={validateEmail}
              valid={emailValid}
            />
            {!emailValid && abandonedEmail ? (
              <AlertWrapper>
                <AlertContactIcon />
              </AlertWrapper>
            ) : null}
          </EmailWrapper>
          <MessageWrapper>
            <Message
              ref={messageRef}
              onBlur={() => setAbandonedMessage(true)}
              onKeyUp={validateMessage}
              valid={messageValid}
            />
            {!messageValid && abandonedMessage ? (
              <AlertMessageWrapper>
                <AlertContactIcon />
              </AlertMessageWrapper>
            ) : null}
          </MessageWrapper>
          <Submit disabled={disabled}>{loading ? <Loading /> : 'Send'}</Submit>
        </Form>
      </FormContainer>
    )
  } else if (status === 'SUCCESS') {
    content = (
      <Container>
        <CloseWrapper onClick={close}>
          <CloseContactIcon />
        </CloseWrapper>
        <FeedbackTitle>Thank you!</FeedbackTitle>
        <FeedbackIcon>
          <CheckContactIcon />
        </FeedbackIcon>
        <FeedbackDescription>
          We&apos;ll get back to you soon.
        </FeedbackDescription>
      </Container>
    )
  } else {
    content = (
      <Container>
        <CloseWrapper onClick={close}>
          <CloseContactIcon />
        </CloseWrapper>
        <FeedbackTitle>Oops!</FeedbackTitle>
        <FeedbackIcon>
          <AlertContactIcon />
        </FeedbackIcon>
        <FeedbackDescription>Something went wrong.</FeedbackDescription>
        <FeedbackWrapperBottom>
          <FeedbackDescriptionBottom>
            Maybe try our social media?
          </FeedbackDescriptionBottom>
          <FeedbackSocial>
            <SocialIconWrapper href="https://www.facebook.com" last={false}>
              <FacebookIcon size={26} />
            </SocialIconWrapper>
            <SocialIconWrapper href="https://www.twitter.com" last={false}>
              <TwitterIcon size={26} />
            </SocialIconWrapper>
            <SocialIconWrapper href="https://www.instagram.com" last={true}>
              <InstagramIcon size={26} />
            </SocialIconWrapper>
          </FeedbackSocial>
        </FeedbackWrapperBottom>
      </Container>
    )
  }
  return <Shadow>{content}</Shadow>
}

ContactForm.propTypes = {
  close: PropTypes.func.isRequired,
}
