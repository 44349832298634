import React, { useState } from 'react'
import styled from 'styled-components'

import { Header } from '../components/home/header'
import { Landing } from '../components/home/landing'
import { Demos } from '../components/home/demos'
import { CustomTracks } from '../components/home/custom-tracks'
import { MixingAndMastering } from '../components/home/mixing-mastering'
import { Plugins } from '../components/home/plugins'
import { ContactForm } from '../components/home/contact-form'
import { MailingRegistrationForm } from '../components/home/mailing-registration-form'
import { Footer } from '../components/home/footer'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`

const doPlay = id => {
  if (typeof document !== 'undefined') {
    const audioElements = document.getElementsByTagName('audio')
    let i
    for (i = 0; i < audioElements.length; i++) {
      const player = audioElements[i]
      player.pause()
    }
    document.getElementById(`${id}-player`).play()
  }
}

const doPause = id => {
  if (typeof document !== 'undefined') {
    document.getElementById(`${id}-player`).pause()
  }
}

const Home = () => {
  const [currentDemo, setCurrentDemo] = useState(null)
  const [playing, setPlaying] = useState(false)
  const [closedContact, setClosedContact] = useState(true)
  const [downloadOs, setDownloadOs] = useState(null)
  const [closedIgnitionDownloadForm, setClosedIgnitionDownloadForm] = useState(
    true,
  )

  const start = ({ src, demoId, songTitle, albumTitle, albumCover }) => {
    doPlay(demoId)
    setPlaying(true)
    setCurrentDemo(demoId)
  }
  const notifyPlayerClosed = () => {
    doPause(currentDemo)
    setCurrentDemo(null)
    setPlaying(false)
  }
  const pause = id => {
    doPause(id)
    setPlaying(false)
  }
  const play = id => {
    doPlay(id)
    setPlaying(true)
  }

  const notifyPlaying = newPlaying => {
    if (newPlaying) {
      doPlay(currentDemo)
      setPlaying(true)
    } else {
      doPause(currentDemo)
      setPlaying(false)
    }
  }

  const closeContact = () => setClosedContact(true)
  const closeIgnitionDownloadForm = () => {
    setDownloadOs(null)
    setClosedIgnitionDownloadForm(true)
  }
  const openContact = () => setClosedContact(false)
  const openIgnitionDownloadForm = os => {
    setDownloadOs(os)
    setClosedIgnitionDownloadForm(false)
  }
  return (
    <Container id="player-goes-here">
      <Header openContact={openContact} />
      <Landing />
      <Demos
        playing={playing}
        currentDemo={currentDemo}
        start={start}
        play={play}
        pause={pause}
        openContact={openContact}
        notifyPlayerClosed={notifyPlayerClosed}
        notifyPlaying={notifyPlaying}
      />
      <CustomTracks openContact={openContact} />
      <MixingAndMastering
        playing={playing}
        currentDemo={currentDemo}
        start={start}
        play={play}
        pause={pause}
        notifyPlayerClosed={notifyPlayerClosed}
        notifyPlaying={notifyPlaying}
      />
      <Plugins
        playing={playing}
        currentDemo={currentDemo}
        start={start}
        play={play}
        pause={pause}
        notifyPlayerClosed={notifyPlayerClosed}
        notifyPlaying={notifyPlaying}
        openIgnitionDownloadForm={openIgnitionDownloadForm}
        closeIgnitionDownloadForm={closeIgnitionDownloadForm}
      />
      <Footer playerOpen={currentDemo !== null} />
      {!closedContact ? <ContactForm close={closeContact} /> : null}
      {!closedIgnitionDownloadForm ? (
        <MailingRegistrationForm
          close={closeIgnitionDownloadForm}
          downloadOs={downloadOs}
        />
      ) : null}
      {typeof document !== 'undefined' ? <div id="portal" /> : null}
    </Container>
  )
}

class RehydratedHome extends React.Component {
  componentDidMount() {
    // Forzar rehidratacion completa en el cliente
    this.forceUpdate()
  }

  render() {
    return <Home />
  }
}

export default RehydratedHome
