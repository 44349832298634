import React from 'react'
import PropTypes from 'prop-types'

import { palette } from '../../styles/theme'
import { Svg } from './svg'

export const LetterIcon = ({ fill = palette.color1, height, width }) => {
  const viewBox = `0 0 ${width} ${height}`
  return (
    <Svg viewBox={viewBox} id="icon-play" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        d="M31.72,0H3.28C1.47,0,0,1.46,0,3.25v19.5C0,24.54,1.47,26,3.28,26h28.44c1.81,0,3.28-1.46,3.28-3.25V3.25 C35,1.46,33.53,0,31.72,0z M31.72,2.17c0.15,0,0.29,0.03,0.42,0.08L17.5,14.82L2.86,2.25C2.99,2.2,3.13,2.17,3.28,2.17L31.72,2.17z M31.72,23.83H3.28c-0.6,0-1.09-0.49-1.09-1.08V4.54l14.6,12.53c0.21,0.18,0.46,0.26,0.72,0.26s0.51-0.09,0.72-0.26l14.6-12.53 v18.21C32.81,23.35,32.32,23.83,31.72,23.83z"
      />
    </Svg>
  )
}

LetterIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}
