import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { palette, spacers } from '../../styles/theme'

const SongDetailsContainer = styled.div`
  margin: 0;
  margin-right: ${spacers.spacer3};
  padding: 0;
  display: flex;
  flex: 3;
  flex-direction: row;
  align-items: center;
`

const AlbumCover = styled.img`
  margin: 0;
  margin-right: ${spacers.spacer3};
  padding: 0;
  border-radius: 5px;
  width: 36px;
  height: 36px;
`

const SongDetailsText = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const DetailText = styled.span`
  margin: 2px;
  padding: 0;
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: 1.1rem;
  line-height: 1;
  color: ${palette.color1};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const SongDetails = ({ songTitle, albumTitle, albumCover }) => (
  <SongDetailsContainer>
    <AlbumCover src={albumCover} />
    <SongDetailsText>
      <DetailText>{albumTitle}</DetailText>
      <DetailText>{songTitle}</DetailText>
    </SongDetailsText>
  </SongDetailsContainer>
)

SongDetails.propTypes = {
  songTitle: PropTypes.string.isRequired,
  albumTitle: PropTypes.string.isRequired,
  albumCover: PropTypes.string.isRequired,
}
