import React from 'react'
import { Svg } from './svg'

export const HoverPauseIcon = () => (
  <Svg viewBox="0 0 66 66" id="icon-pause" xmlns="http://www.w3.org/2000/svg">
    <g>
      <circle fill="#E4E6EB" cx="33" cy="33" r="33" />
      <g>
        <path
          fill="#121212"
          d="M22,20.64l0,24.73c0,0.35,0.25,0.64,0.55,0.64h4.9c0.3,0,0.55-0.28,0.55-0.64V20.64 c0-0.35-0.25-0.64-0.55-0.64h-4.9C22.25,20,22,20.28,22,20.64z"
        />
        <path
          fill="#121212"
          d="M38,20.64l0,24.73c0,0.35,0.25,0.64,0.55,0.64h4.9c0.3,0,0.55-0.28,0.55-0.64V20.64 c0-0.35-0.25-0.64-0.55-0.64h-4.9C38.25,20,38,20.28,38,20.64z"
        />
      </g>
    </g>
  </Svg>
)
