import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Range from '../range'

import { palette, spacers } from '../../styles/theme'
import { device } from '../../device'

import { PlayPauseButton } from '../buttons/play-pause-button'
import { CloseButton } from '../buttons/close-button'
import { SongDetails } from './song-details'

const Container = styled.div`
  margin: 0;
  padding: 1px 0;
  display: ${props => (props.active ? 'flex' : 'none')};
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 56px;
  background-color: ${palette.color5};
  border-top: 1px solid ${palette.color3};
`

const HiddenContainer = styled.div`
  display: none;
`

const RangeContainer = styled.div`
  margin: 0;
  margin-right: ${spacers.spacer3};
  padding: 0;
  flex-direction: column;
  justify-content: center;
  display: none;
  @media ${device.intermediate} {
    display: flex;
    flex: 6;
  }
`

const FlexiblePart = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1;
`

export const Player = ({
  id,
  albumTitle,
  albumCover,
  notifyClosed,
  notifyPlaying,
  playing,
  songTitle,
  src,
  active,
}) => {
  const [url, setUrl] = useState(null)
  const [played, setPlayed] = useState(0)
  const [seeking, setSeeking] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadedMetadata, setLoadedMetadata] = useState(false)

  const player = useRef(null)

  useEffect(() => {
    setPlayed(0)
    setUrl(src)
  }, [src])

  useEffect(() => {
    if (!active) {
      player.current.currentTime = 0
      setPlayed(0)
    }
  }, [active])

  const handlePlayPause = () => {
    notifyPlaying(!playing)
  }

  const handleStop = () => {
    setPlayed(0)
    notifyClosed()
  }

  const handleSeekMouseDown = e => {
    if (loadedMetadata) {
      setSeeking(true)
    }
  }

  const handleSeekChange = e => {
    if (loadedMetadata) {
      setPlayed(parseFloat(e.target.value))
    }
  }

  const handleSeekMouseUp = e => {
    if (loadedMetadata) {
      const newValue = parseFloat(e.target.value)
      player.current.currentTime = newValue * player.current.duration
      setSeeking(false)
    }
  }

  const onTimeUpdate = event => {
    if (!seeking) {
      const audioNode = player.current
      const currentTime = audioNode.currentTime
      const trackDuration = audioNode.duration
      setPlayed(currentTime / trackDuration)
    }
  }

  const onLoadStart = () => {
    setLoading(true)
  }

  const onWaiting = () => {
    setLoading(true)
  }

  const onLoadedMetadata = () => {
    setLoadedMetadata(true)
  }

  const onCanPlay = () => {
    setLoading(false)
  }

  return (
    <>
      <Container active={active}>
        <PlayPauseButton loading={loading} onClick={handlePlayPause} playing={playing} />
        <FlexiblePart>
          <RangeContainer>
            <Range
              hideThumb
              type="range"
              min={0}
              max={1}
              step={0.0001}
              value={played}
              trackColor={{ r: 18, g: 18, b: 18, a: 1 }}
              fillColor={{ r: 186, g: 190, b: 194, a: 1 }}
              height={5}
              width="100%"
              readOnly={!loadedMetadata}
              onMouseDown={handleSeekMouseDown}
              onChange={handleSeekChange}
              onMouseUp={handleSeekMouseUp}
            />
          </RangeContainer>
          <SongDetails
            albumTitle={albumTitle}
            songTitle={songTitle}
            albumCover={albumCover}
          />
        </FlexiblePart>
        <CloseButton onClick={handleStop} />
        <HiddenContainer>
          <audio
            id={`${id}-player`}
            preload="none"
            ref={player}
            src={url}
            onTimeUpdate={onTimeUpdate}
            onLoadStart={onLoadStart}
            onWaiting={onWaiting}
            onLoadedMetadata={onLoadedMetadata}
            onCanPlay={onCanPlay}
            controls={false}
          />
        </HiddenContainer>
      </Container>
    </>
  )
}

Player.propTypes = {
  id: PropTypes.string,
  albumTitle: PropTypes.string,
  albumCover: PropTypes.string.isRequired,
  notifyClosed: PropTypes.func.isRequired,
  notifyPlaying: PropTypes.func.isRequired,
  playing: PropTypes.bool.isRequired,
  songTitle: PropTypes.string,
  src: PropTypes.string,
  active: PropTypes.bool,
}
