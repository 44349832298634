import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { device } from '../../device'
import { HoverPlayIcon } from '../../components/icons/hover-play'
import { HoverPauseIcon } from '../../components/icons/hover-pause'
import { DownloadIcon } from '../../components/icons/download'
import {
  palette,
  maxContentWidth,
  spacers,
  titleStyles,
  descriptionStyles,
} from '../../styles/theme'
import { Player } from '../player'
import { layers } from '../../styles/layers'

import ignitionAmp from './images/ignition.png'

const DemoCardWrapper = styled.div`
  padding: 0;
  margin: 0;
  flex: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
`

const AlbumCoverContainer = styled.div`
  margin: 0;
  margin-bottom: ${spacers.spacer1};
  width: 100%;
  padding-bottom: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-image: url("${props => props.albumCover}");
  background-size: cover;
  position: relative;
`

const AmpAndDemo = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${spacers.spacer4};
  @media ${device.intermediate} {
    flex-direction: row;
    margin-bottom: ${spacers.spacer4};
  }
`

const AmpImageWrapper = styled.div`
  display: flex;
  margin-bottom: ${spacers.spacer4};
  justify-content: center;
  @media ${device.intermediate} {
    margin-bottom: 0;
    margin-right: ${spacers.spacer4};
  }
`

const AmpImage = styled.img.attrs({
  src: ignitionAmp,
})`
  max-width: calc(100% - 44px);
`

const PlayPauseButtonOuter = styled.div`
  position: absolute;
  z-index: ${layers.background};
  height: 100%;
  width: 100%;
  top: 0;
  cursor: pointer;
  display: none;
  ${DemoCardWrapper}:hover & {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const PlayPauseButtonInner = styled.div`
  width: 35%;
  height: 35%;
`

const PlayPauseButton = ({ children }) => (
  <PlayPauseButtonOuter>
    <PlayPauseButtonInner>{children}</PlayPauseButtonInner>
  </PlayPauseButtonOuter>
)

PlayPauseButton.propTypes = {
  children: PropTypes.node.isRequired,
}

const AlbumCover = ({ demoId, currentDemo, playing, albumCover }) => (
  <AlbumCoverContainer albumCover={albumCover}>
    <PlayPauseButton>
      {demoId === currentDemo && playing ? (
        <HoverPauseIcon />
      ) : (
        <HoverPlayIcon />
      )}
    </PlayPauseButton>
  </AlbumCoverContainer>
)

AlbumCover.propTypes = {
  demoId: PropTypes.string.isRequired,
  albumCover: PropTypes.string.isRequired,
  currentDemo: PropTypes.string,
  playing: PropTypes.bool.isRequired,
}

const DescriptionCard = styled.span`
  margin: 0;
  margin-bottom: ${spacers.spacer1};
  padding: 0;
  display: inline-block;
  color: ${palette.color6};
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
`

const Modal = ({ children }) => {
  const portalDiv =
    typeof document !== 'undefined' && document.getElementById('portal')
  return portalDiv ? ReactDOM.createPortal(children, portalDiv) : null
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

const DemoCard = ({
  time,
  albumTitle,
  albumCover,
  genre,
  songTitle,
  currentDemo,
  id,
  src,
  playing,
  start,
  play,
  pause,
  notifyPlayerClosed,
  notifyPlaying,
}) => {
  // TODO: Optimize this shit with useCallback or smth
  const onClick = () => {
    if (playing && currentDemo === id) {
      pause(id)
    } else if (!playing && currentDemo === id) {
      play(id)
    } else {
      start({ src, demoId: id, songTitle, albumTitle, albumCover })
    }
  }
  return (
    <>
      <DemoCardWrapper onClick={onClick}>
        <AlbumCover
          demoId={id}
          currentDemo={currentDemo}
          playing={playing}
          albumCover={albumCover}
        />
        <DescriptionCard>{time}</DescriptionCard>
      </DemoCardWrapper>
      {typeof document !== 'undefined' ? (
        <Modal>
          <Player
            id={id}
            active={currentDemo === id}
            albumTitle={albumTitle}
            albumCover={albumCover}
            notifyClosed={notifyPlayerClosed}
            notifyPlaying={notifyPlaying}
            playing={playing}
            songTitle={songTitle}
            src={src}
          />
        </Modal>
      ) : null}
    </>
  )
}

DemoCard.propTypes = {
  time: PropTypes.string.isRequired,
  albumTitle: PropTypes.string.isRequired,
  albumCover: PropTypes.string.isRequired,
  songTitle: PropTypes.string.isRequired,
  genre: PropTypes.string.isRequired,
  start: PropTypes.func.isRequired,
  play: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  currentDemo: PropTypes.string,
  playing: PropTypes.bool.isRequired,
  notifyPlayerClosed: PropTypes.func.isRequired,
  notifyPlaying: PropTypes.func.isRequired,
}

const CardsCarousel = styled.div`
  margin: 0;
  padding-top: ${spacers.spacer2};
  padding-left: ${spacers.spacer2};
  padding-bottom: 0;
  padding-right: ${spacers.spacer2};
  max-width: 176px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${palette.color2};
  border-radius: 5px;
  @media ${device.intermediate} {
    margin-right: 36px;
    width: 176px;
    height: 200px;
  }
  @media ${device.web} {
    margin: 0;
  }
`

const Container = styled.section.attrs({
  id: 'plugins',
})`
  margin: 0 0 36px 0;
  width: 100%;
  max-width: ${maxContentWidth}px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.intermediate} {
    margin-bottom: 48px;
  }
  @media ${device.web} {
    margin-bottom: 72px;
  }
`

const Title = styled.h2`
  ${titleStyles}
  margin-top: 0;
  margin-left: ${spacers.spacer2};
  margin-bottom: ${spacers.spacer1};
  margin-right: ${spacers.spacer2};
  padding: 0;
  color: ${palette.color6};
  @media ${device.intermediate} {
    margin-bottom: ${spacers.spacer3};
  }
`

const Description = styled.p`
  ${descriptionStyles}
  margin-top: 0;
  margin-left: ${spacers.spacer2};
  margin-bottom: ${spacers.spacer5};
  margin-right: ${spacers.spacer2};
  max-width: 700px;
  padding: 0;
  text-align: center;
  color: ${palette.color6};
  @media ${device.intermediate} {
    margin-bottom: ${spacers.spacer3};
  }
  @media ${device.web} {
    margin-bottom: ${spacers.spacer5};
  }
`

const DescriptionBold = styled.span`
  ${descriptionStyles}
  font-family: 'Jorge-Luis-Cafe-Bold';
`

const DownloadDescription = styled.span`
  ${descriptionStyles}
  margin: 0;
  margin-bottom: ${spacers.spacer1};
  padding: 0;
  display: inline-block;
  text-align: center;
  color: ${palette.color6};
  @media ${device.intermediate} {
    margin-bottom: ${spacers.spacer3};
  }
`

const DownloadButtonsWrapper = styled.div`
  margin: 0;
  padding-top: ${spacers.spacer1};
  padding-left: ${spacers.spacer2};
  padding-bottom: ${spacers.spacer2};
  padding-right: ${spacers.spacer2};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 5px;
  background: ${palette.color2};
  width: 522px;
  max-width: calc(100% - 44px);
  @media ${device.intermediate} {
    padding: ${spacers.spacer3};
    width: 776px;
  }
`

const DownloadButton = styled.a`
  margin: 0;
  width: 100%;
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: 1.5rem;
  line-height: 2;
  text-align: center;
  text-decoration: none;
  box-shadow: none;
  border: none;
  border-radius: 25px;
  background: ${palette.color5};
  color: ${palette.color1};
  cursor: pointer;
  margin-bottom: ${props => (props.last ? 0 : spacers.spacer1)};
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.intermediate} {
    width: 286px;
    height: ${spacers.spacer6};
  }
`

const Link = styled.a`
  ${descriptionStyles}
  color: ${palette.color6};
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  @media ${device.intermediate} {
    line-height: 1.5;
  }
`

export const Plugins = ({
  currentDemo,
  start,
  play,
  pause,
  playing,
  notifyPlayerClosed,
  notifyPlaying,
  openIgnitionDownloadForm,
}) => (
  <Container>
    <Title>Plugins</Title>
    <Description>
      <DescriptionBold>THM Ignition</DescriptionBold> aims to be the simplest
      usable amp plugin you could have in your DAW. It's an all-in-one high-gain
      amplifier + cab simulator.
    </Description>
    <AmpAndDemo>
      <AmpImageWrapper>
        <AmpImage />
      </AmpImageWrapper>
      <CardsCarousel>
        <DemoCard
          time="Demo"
          albumTitle="Ignition Guitar Amp"
          songTitle="Demo"
          albumCover="generic-waveform.png"
          genre="Rock"
          currentDemo={currentDemo}
          id="ignition-demo"
          src="ignition-demo.mp3"
          start={start}
          play={play}
          pause={pause}
          playing={playing}
          notifyPlayerClosed={notifyPlayerClosed}
          notifyPlaying={notifyPlaying}
        />
      </CardsCarousel>
    </AmpAndDemo>
    <Description>
      Hack the code on{' '}
      <Link
        href="https://github.com/sdeleon28/IgnitionGuitarAmp"
        target="_blank"
      >
        the GitHub repository
      </Link>
      .
    </Description>
    <DownloadButtonsWrapper>
      <DownloadDescription>
        Grab an installer. It&apos;s free!
      </DownloadDescription>
      <DownloadButton onClick={() => openIgnitionDownloadForm('mac')}>
        <DownloadIcon />
        Mac OS
      </DownloadButton>
      <DownloadButton last onClick={() => openIgnitionDownloadForm('win')}>
        <DownloadIcon />
        Windows
      </DownloadButton>
    </DownloadButtonsWrapper>
  </Container>
)

Plugins.propTypes = {
  currentDemo: PropTypes.string,
  start: PropTypes.func.isRequired,
  play: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
  playing: PropTypes.bool.isRequired,
  notifyPlayerClosed: PropTypes.func.isRequired,
  notifyPlaying: PropTypes.func.isRequired,
  openIgnitionDownloadForm: PropTypes.func.isRequired,
}
