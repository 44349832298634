import React from 'react'
import { Svg } from './svg'
import { palette } from '../../styles/theme'

export const CheckContactIcon = () => (
  <Svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fill={palette.color1}
        d="M33,0C14.8,0,0,14.8,0,33s14.8,33,33,33s33-14.8,33-33S51.2,0,33,0z M33,62C17,62,4,49,4,33S17,4,33,4 c16,0,29,13,29,29S49,62,33,62z"
      />
      <path
        fill={palette.color1}
        d="M47.4,21.2c-0.2-0.2-0.5,0-0.8,0.2L29,39l-9.7-9.7c-0.2-0.2-0.4-0.2-0.6,0l-2.5,2.5c-0.2,0.2-0.1,0.4,0,0.6 l12.4,12.4c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0.2,0.1,0.4,0.1,0.5,0l2.5-2.5c0,0,0,0,0,0l17.9-17.9c0.3-0.3,0.4-0.6,0.2-0.8L47.4,21.2z"
      />
    </g>
  </Svg>
)
