import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import isEmail from 'validator/es/lib/isEmail'
import isLength from 'validator/es/lib/isLength'

import { layers } from '../../styles/layers'
import {
  palette,
  spacers,
  titleStyles,
  descriptionStyles,
  absoluteTitleStyles,
} from '../../styles/theme'
import { CloseContactIcon } from '../icons/close-contact'
import { AlertContactIcon } from '../icons/alert-contact'
import { CheckContactIcon } from '../icons/check-contact'

const effectLoading = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const Loading = styled.div`
  width: 28px;
  height: 28px;
  margin: auto;
  border: 3px solid ${palette.color6};
  border-radius: 50%;
  border-top: 3px solid transparent;
  animation: ${effectLoading} 0.8s linear infinite;
`

const effectWindow = keyframes`
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
`

const Shadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${layers.contact};
  padding: ${spacers.spacer2};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
`

const Container = styled.div`
  position: relative;
  margin: 0;
  padding: ${spacers.spacer2};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${palette.color6};
  border-radius: 15px;
  width: 90%;
  max-width: 350px;
`

const FormContainer = styled(Container)`
  animation-name: ${effectWindow};
  animation-duration: 0.22s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
`

const HeaderContact = styled.div`
  margin: 0;
  margin-bottom: ${spacers.spacer2};
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CloseWrapper = styled.div`
  position: absolute;
  top: ${spacers.spacer1};
  right: ${spacers.spacer1};
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  cursor: pointer;
`

const ContactTitle = styled.h2`
  ${titleStyles}
  margin: 0;
  padding: 0;
  color: ${palette.color1};
`

const Form = styled.form`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const FieldWrapper = styled.div`
  margin: 0;
  margin-bottom: ${spacers.spacer2};
  padding: 0 ${spacers.spacer1};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: ${palette.color1};
  border-radius: 5px;
`

const Name = styled.input.attrs({
  id: 'idName',
  type: 'text',
  name: 'name',
  autocomplete: 'off',
  required: true,
  placeholder: 'First Name',
})`
  ${descriptionStyles}
  margin: 1px 0;
  padding: 0;
  width: 100%;
  max-height: 100%;
  border: none;
  background: ${palette.color1};
  color: ${palette.color6};
  :focus {
    outline: none;
  }
`

const Email = styled.input.attrs({
  id: 'idEmail',
  type: 'email',
  name: 'email',
  autocomplete: 'off',
  required: true,
  placeholder: 'email@example.com',
})`
  ${descriptionStyles}
  margin: 1px 0;
  padding: 0;
  width: 100%;
  max-height: 100%;
  border: none;
  background: ${palette.color1};
  color: ${palette.color6};
  :focus {
    outline: none;
  }
`

const AcceptCheckbox = styled.input.attrs({
  id: 'idAccept',
  type: 'checkbox',
  name: 'accept',
  autocomplete: 'off',
  required: true,
})`
  margin-right: ${spacers.spacer1};
`

const AlertWrapper = styled.div`
  margin: 0;
  margin-left: ${spacers.spacer1};
  padding: 0;
  width: 22px;
  height: 22px;
`

const Submit = styled.button`
  ${absoluteTitleStyles}
  line-height: 1.33;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 30px;
  background: ${props => (props.disabled ? palette.color5 : palette.color1)};
  color: ${props => (props.disabled ? palette.color4 : palette.color6)};
  cursor: ${props => (props.disabled ? 'no-drop' : 'pointer')};
  outline: none;
`

const FeedbackTitle = styled.h2`
  ${titleStyles}
  margin: 0;
  margin-bottom: ${spacers.spacer2};
  padding: 0;
  font-family: 'Jorge-Luis-Cafe-Cafe';
  color: ${palette.color1};
`

const effectIcon = keyframes`
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`

const FeedbackIcon = styled.div`
  margin: 0;
  margin-bottom: ${spacers.spacer2};
  padding: 0;
  width: 60px;
  height: 60px;
  animation-name: ${effectIcon};
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
`

const Description = styled.span`
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: 20px;
  line-height: 1;
  margin: 0;
  margin-bottom: ${spacers.spacer2};
  padding: 0;
  color: ${palette.color1};
`

const FeedbackDescription = styled.span`
  ${descriptionStyles}
  line-height: 1;
  margin: 0;
  padding: 0;
  color: ${palette.color1};
  text-align: center;
`

const Link = styled.a`
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: 20px;
  line-height: 1;
  color: ${palette.color3};
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
`

export const MailingRegistrationForm = ({ close, downloadOs }) => {
  const [status, setStatus] = useState('INITIAL')
  const [loading, setLoading] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [nameValid, setNameValid] = useState(false)
  const [abandonedName, setAbandonedName] = useState(false)
  const [abandonedEmail, setAbandonedEmail] = useState(false)
  const [accepted, setAccepted] = useState(false)

  const nameRef = useRef(null)
  const emailRef = useRef(null)
  const acceptRef = useRef(null)

  const toggleAccept = () => {
    setAccepted(!accepted)
  }

  const validateEmail = () => {
    setEmailValid(isEmail(emailRef.current.value.trim()))
  }

  const validateName = () => {
    setNameValid(
      isLength(nameRef.current.value.trim(), {
        min: 1,
        max: 10000,
      }),
    )
  }

  const submitForm = ev => {
    ev.preventDefault()
    setLoading(true)
    if (!loading) {
      if (
        process.env.NODE_ENV !== 'development' &&
        emailValid &&
        nameValid &&
        accepted
      ) {
        const form = ev.target
        const data = new FormData(form)
        const xhr = new XMLHttpRequest()
        xhr.open(form.method, form.action)
        xhr.setRequestHeader('Accept', 'application/json')
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== XMLHttpRequest.DONE) return
          if (xhr.status === 200) {
            form.reset()
            setStatus('SUCCESS')
            setLoading(false)
          } else {
            setStatus('ERROR')
            setLoading(false)
          }
        }
        xhr.send(data)
      } else {
        setTimeout(() => {
          setStatus('ERROR')
          setLoading(false)
        }, 2000)
      }
    }
  }

  const disabled = !(emailValid && nameValid && accepted)
  let content
  if (status === 'INITIAL') {
    content = (
      <FormContainer>
        <HeaderContact>
          <ContactTitle>Subscribe</ContactTitle>
          <CloseWrapper onClick={close}>
            <CloseContactIcon />
          </CloseWrapper>
        </HeaderContact>
        <Form
          onSubmit={submitForm}
          action="/api/get-ignition"
          method="POST"
        >
          <Description>
            We will email your download link upon subscription.
          </Description>
          <FieldWrapper>
            <Name
              ref={nameRef}
              onBlur={() => setAbandonedName(true)}
              onKeyUp={validateName}
              valid={nameValid}
            />
            {!nameValid && abandonedName ? (
              <AlertWrapper>
                <AlertContactIcon />
              </AlertWrapper>
            ) : null}
          </FieldWrapper>
          <FieldWrapper>
            <Email
              ref={emailRef}
              onBlur={() => setAbandonedEmail(true)}
              onKeyUp={validateEmail}
              valid={emailValid}
            />
            {!emailValid && abandonedEmail ? (
              <AlertWrapper>
                <AlertContactIcon />
              </AlertWrapper>
            ) : null}
          </FieldWrapper>
          <Description>
            <AcceptCheckbox
              ref={acceptRef}
              onClick={toggleAccept}
            />
            Yes, I want to receive product updates from THM Studio. Read more about how we use your info{' '}
            <Link href="/privacy-policy/" target="_blank">here</Link>.
          </Description>
          <input type="hidden" name="downloadOs" value={downloadOs} />
          <Submit disabled={disabled}>{loading ? <Loading /> : 'Send'}</Submit>
        </Form>
      </FormContainer>
    )
  } else if (status === 'SUCCESS') {
    content = (
      <Container>
        <CloseWrapper onClick={close}>
          <CloseContactIcon />
        </CloseWrapper>
        <FeedbackTitle>That worked!</FeedbackTitle>
        <FeedbackIcon>
          <CheckContactIcon />
        </FeedbackIcon>
        <FeedbackDescription>
          We&apos;re generating your download link. It should land in your email inbox shortly.
        </FeedbackDescription>
      </Container>
    )
  } else {
    content = (
      <Container>
        <CloseWrapper onClick={close}>
          <CloseContactIcon />
        </CloseWrapper>
        <FeedbackTitle>Oops!</FeedbackTitle>
        <FeedbackIcon>
          <AlertContactIcon />
        </FeedbackIcon>
        <FeedbackDescription>Something went wrong. Please, try again later.</FeedbackDescription>
      </Container>
    )
  }
  return <Shadow>{content}</Shadow>
}

MailingRegistrationForm.propTypes = {
  close: PropTypes.func.isRequired,
  downloadOs: PropTypes.string.isRequired,
}
