import React from 'react'
import { Svg } from './svg'
import { palette } from '../../styles/theme'

export const PlayPlayerIcon = () => (
  <Svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={palette.color1}
      d="M16.82,10.3L0.54,19.95c-0.23,0.14-0.53-0.03-0.53-0.3l0-19.29c0-0.27,0.3-0.44,0.53-0.3L16.82,9.7 C17.05,9.83,17.05,10.17,16.82,10.3z"
    />
  </Svg>
)
