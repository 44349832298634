import React from 'react'
import { Svg } from './svg'
import { palette } from '../../styles/theme'

export const CloseContactIcon = () => (
  <Svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fill={palette.color1}
        d="M11,0C4.9,0,0,4.9,0,11s4.9,11,11,11s11-4.9,11-11S17.1,0,11,0z M11,20.4c-5.2,0-9.4-4.2-9.4-9.4 S5.8,1.6,11,1.6s9.4,4.2,9.4,9.4S16.2,20.4,11,20.4z"
      />
      <path
        fill={palette.color1}
        d="M15.7,7.1l-0.8-0.8c-0.1-0.1-0.2,0-0.3,0.1L11,10L7.4,6.4C7.3,6.3,7.2,6.3,7.1,6.3L6.3,7.1 c-0.1,0.1,0,0.2,0.1,0.3L10,11l-3.6,3.6c-0.1,0.1-0.2,0.3-0.1,0.3l0.8,0.8c0.1,0.1,0.2,0,0.3-0.1L11,12l3.6,3.6 c0.1,0.1,0.3,0.2,0.3,0.1l0.8-0.8c0.1-0.1,0-0.2-0.1-0.3L12,11l3.6-3.6C15.7,7.3,15.7,7.2,15.7,7.1z"
      />
    </g>
  </Svg>
)
