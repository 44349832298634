import React from 'react'
import styled from 'styled-components'

import { device } from '../../device'
import { spacers } from '../../styles/theme'
import landingSmall from './images/landing@600.jpg'
import landingLarge from './images/landing@1600.jpg'

const Container = styled.section.attrs({
  id: 'home',
})`
  background-image: url(${landingSmall});
  margin: 0;
  margin-bottom: ${spacers.spacer5};
  display: flex;
  background-size: cover;
  background-position: center;
  height: calc(100vh - 55px);
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media ${device.intermediate} {
    background-image: url(${landingLarge});
  }
`

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TitleText = styled.h1`
  color: white;
  text-align: center;
  font-family: 'Jorge-Luis-Cafe-Bold';
  font-size: 96px;
  line-height: 1;
`

const Description = styled.p`
  color: white;
  text-align: center;
  font-family: 'Jorge-Luis-Cafe-Cafe';
  line-height: 1;
  margin: 10px 20px 0 20px;
  font-size: 2.5em;
  @media ${device.web} {
    font-size: 72px;
  }
`

const Title = () => (
  <TitleContainer>
    <TitleText>THM</TitleText>
    <Description>We care about your music needs</Description>
  </TitleContainer>
)

export const Landing = () => (
  <Container>
    <Title />
  </Container>
)
