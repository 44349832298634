import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { ClosePlayerIcon } from '../icons/close-player'

const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const IconWrapper = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

export const CloseButton = ({ onClick }) => (
  <Container onClick={onClick}>
    <IconWrapper>
      <ClosePlayerIcon />
    </IconWrapper>
  </Container>
)

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}
