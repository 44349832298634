import React from 'react'
import { Svg } from './svg'

export const HoverPlayIcon = () => (
  <Svg viewBox="0 0 66 66" id="icon-play" xmlns="http://www.w3.org/2000/svg">
    <g>
      <circle fill="#E4E6EB" cx="33" cy="33" r="33" />
      <g>
        <path
          fill="#121212"
          d="M45.97,33.39l-21.1,12.18c-0.3,0.17-0.67-0.04-0.67-0.39V20.82c0-0.35,0.37-0.56,0.67-0.39l21.1,12.18 C46.27,32.78,46.27,33.22,45.97,33.39z"
        />
      </g>
    </g>
  </Svg>
)
