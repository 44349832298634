import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { device } from '../../device'
import {
  palette,
  spacers,
  titleStyles,
  descriptionStyles,
} from '../../styles/theme'
import { layers } from '../../styles/layers'
import { HamburguerIcon } from '../icons/hamburguer-icon'
import { LogoIcon } from '../icons/logo-icon'
import { LetterIcon } from '../icons/letter-icon'
import { Menu } from './menu'
import { smoothScroll } from '../../utils/smoothScroll'

const hamburguerDimensions = {
  height: 26,
  width: 35,
}

const logoDimensions = {
  height: 36,
  width: 33.99,
}

const letterDimensions = {
  height: 26,
  width: 35,
}

const HeaderElement = styled.header`
  margin: 0;
  padding-top: 15px;
  padding-left: ${spacers.spacer3};
  padding-bottom: 15px;
  padding-right: ${spacers.spacer3};
  height: 56px;
  position: sticky;
  z-index: ${layers.header};
  top: 0;
  display: flex;
  background-color: ${palette.color6};
`

const Nav = styled.nav`
  display: none;
  @media ${device.intermediate} {
    margin-top: 0;
    margin-left: ${spacers.spacer3};
    margin-bottom: 0;
    margin-right: ${spacers.spacer3};
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

const NavItems = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const NavItemContainer = styled.li`
  margin: 0;
  margin-right: ${props => (props.last ? 0 : spacers.spacer3)};
  padding: 0;
`

const NavItemLink = styled.a`
  ${descriptionStyles}
  line-height: 1;
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  color: ${palette.color1};
  transition: 0.3s color ease-in-out;
  :hover {
    color: ${palette.color3};
  }
`

const NavItem = ({ href, target, children, last, handleClick }) => (
  <NavItemContainer last={last}>
    <NavItemLink href={href} target={target} onClick={handleClick}>
      {children}
    </NavItemLink>
  </NavItemContainer>
)

NavItem.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  children: PropTypes.string.isRequired,
  last: PropTypes.bool,
  handleClick: PropTypes.func,
}

const HamburguerSection = styled.section`
  margin-right: ${spacers.spacer3};
  height: ${hamburguerDimensions.height}px;
  width: ${hamburguerDimensions.width}px;
  flex-direction: column;
  align-items: center;
  @media ${device.intermediate} {
    display: none;
  }
`

const Button = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`

const LogoSection = styled.section`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const LogoLink = styled.a.attrs({ href: '/' })`
  margin: 0;
  padding: 0;
  height: ${logoDimensions.height}px;
  width: ${logoDimensions.width}px;
  display: block;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
`

const LogoLetters = styled.a.attrs({ href: '/' })`
  ${titleStyles}
  line-height: 1;
  margin: 0;
  margin-left: ${spacers.spacer3};
  padding: 0;
  display: none;
  color: ${palette.color1};
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  @media ${device.web} {
    display: block;
  }
`

const ContactSection = styled.section`
  margin: 0;
  padding: 0;
  height: ${letterDimensions.height}px;
  width: ${letterDimensions.width}px;
`

const LeftBox = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`

const RightBox = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`

export const Header = ({ openContact }) => {
  const [active, setActive] = useState(false)

  const toggleActive = () => {
    if (active) setActive(false)
    else setActive(true)
  }

  const handleClick = e => smoothScroll(e)

  return (
    <HeaderElement>
      <LeftBox>
        <HamburguerSection>
          <Button onClick={toggleActive}>
            <HamburguerIcon
              height={hamburguerDimensions.height}
              width={hamburguerDimensions.width}
            />
          </Button>
        </HamburguerSection>
        <LogoSection>
          <LogoLink>
            <LogoIcon
              height={logoDimensions.height}
              width={logoDimensions.width}
            />
          </LogoLink>
          <LogoLetters>THM</LogoLetters>
        </LogoSection>
        <Nav>
          <NavItems>
            <NavItem href="#home" handleClick={handleClick}>
              Home
            </NavItem>
            <NavItem href="#tracks" handleClick={handleClick}>
              Tracks
            </NavItem>
            <NavItem href="#custom-tracks" handleClick={handleClick}>
              Custom
            </NavItem>
            <NavItem href="#mixing" handleClick={handleClick}>
              Mixing &amp; Mastering
            </NavItem>
            <NavItem href="#plugins" handleClick={handleClick}>
              Plugins
            </NavItem>
            <NavItem href="/blog" target="_blank" last={true}>
              Blog
            </NavItem>
          </NavItems>
        </Nav>
      </LeftBox>
      <RightBox>
        <ContactSection>
          <Button onClick={openContact}>
            <LetterIcon
              height={letterDimensions.height}
              width={letterDimensions.width}
            />
          </Button>
        </ContactSection>
      </RightBox>
      <Menu open={active} closeMenu={toggleActive} />
    </HeaderElement>
  )
}

Header.propTypes = {
  openContact: PropTypes.func.isRequired,
}
